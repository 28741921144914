export const SUB_PAGES = {
  SOLUTIONS: {
    titles: ['SCANSHIP - SEA BASED', 'ETIA - LAND BASED'],
    paths: ['/solutions/scanship-sea-based', '/solutions/etia-land-based'],
    subPages: ['scanship-sea-based', 'etia-land-based'],
    solutionsTabs: [
      {
        tabName: 'scanship-sea-based',
        tabValue: 0,
        tabLink: '/solutions/scanship-sea-based',
      },
      {
        tabName: 'etia-land-based',
        tabValue: 1,
        tabLink: '/solutions/etia-land-based',
      },
    ],
  },
  NEWSANDMEDIA: {
    titles: ['NEWS', 'PODCAST', 'VIDEO'],
    paths: [
      '/news-and-media/news',
      '/news-and-media/podcast',
      '/news-and-media/video',
    ],
    subPages: ['news', 'podcast', 'video'],
    newsAndMediaTabs: [
      {
        tabName: 'news',
        tabValue: 0,
        tabLink: '/news-and-media/news',
      },
      {
        tabName: 'podcast',
        tabValue: 1,
        tabLink: '/news-and-media/podcast',
      },
      {
        tabName: 'video',
        tabValue: 2,
        tabLink: '/news-and-media/video',
      },
    ],
  },
  OUR_BRANDS: {
    titles: [
      'SCANSHIP',
      'C.H. EVENSEN',
      'BIOGREEN',
      'SPIRAJOULE',
      'SAFESTERIL',
      'OZOSTERIL',
    ],
    paths: [
      '/our-brands/scanship',
      '/our-brands/chevensen',
      '/our-brands/biogreen',
      '/our-brands/spirajoule',
      '/our-brands/safesteril',
      '/our-brands/ozosteril',
    ],
    subPages: [
      'scanship',
      'chevensen',
      'biogreen',
      'spirajoule',
      'safesteril',
      'ozosteril',
    ],
    ourBrandsTabs: [
      {
        tabName: 'scanship',
        tabValue: 0,
        tabLink: '/our-brands/scanship',
      },
      {
        tabName: 'chevensen',
        tabValue: 1,
        tabLink: '/our-brands/chevensen',
      },
      {
        tabName: 'biogreen',
        tabValue: 2,
        tabLink: '/our-brands/biogreen',
      },
      {
        tabName: 'spirajoule',
        tabValue: 3,
        tabLink: '/our-brands/spirajoule',
      },
      {
        tabName: 'safesteril',
        tabValue: 4,
        tabLink: '/our-brands/safesteril',
      },
      {
        tabName: 'ozosteril',
        tabValue: 5,
        tabLink: '/our-brands/ozosteril',
      },
    ],
  },
  ABOUT_US: {
    titles: ['ABOUT US', 'MANAGEMENT & BOARD', 'VOICES OF VOW', 'WORK WITH US'],
    paths: [
      '/about-us/about-us',
      '/about-us/management-and-board',
      '/about-us/voices-of-vow',
      '/about-us/work-with-us',
    ],
    subPages: [
      'about-us',
      'management-and-board',
      'voices-of-vow',
      'work-with-us',
    ],
    aboutUsTabs: [
      {
        tabName: 'about-us',
        tabValue: 0,
        tabLink: '/about-us/about-us',
      },
      {
        tabName: 'management-and-board',
        tabValue: 1,
        tabLink: '/about-us/management-and-board',
      },
      {
        tabName: 'voices-of-vow',
        tabValue: 2,
        tabLink: '/about-us/voices-of-vow',
      },
      {
        tabName: 'work-with-us',
        tabValue: 3,
        tabLink: '/about-us/work-with-us',
      },
    ],
  },
  BUSINESS: {
    titles: ['OUR BUSINESS', 'SOLUTIONS'],
    paths: ['/business', '/business'],
  },
  INVESTORS: {
    titles: [
      'SHARE INFORMATION',
      'RESULTS & PRESENTATIONS',
      'NEWS',
      'FINANCIAL CALENDAR',
      'GENERAL MEETINGS',
      'IR CONTACTS',
    ],
    paths: [
      '/investor/share-information',
      '/investor/results-reports',
      '/investor/news',
      '/investor/financial-calendar',
      '/investor/general-meetings',
      '/investor/ir-contacts',
    ],
    subPages: [
      'share-information',
      'results-reports',
      'news',
      'financial-calendar',
      'general-meetings',
      'ir-contacts',
    ],
    investorTabs: [
      {
        tabName: 'share-information',
        tabValue: 0,
        tabLink: '/investor/share-information',
      },
      {
        tabName: 'result-reports',
        tabValue: 1,
        tabLink: '/investor/results-reports',
      },
      {
        tabName: 'news',
        tabValue: 2,
        tabLink: '/investor/news',
      },
      {
        tabName: 'financial-calendar',
        tabValue: 3,
        tabLink: '/investor/financial-calendar',
      },
      {
        tabName: 'general-meetings',
        tabValue: 4,
        tabLink: '/investor/general-meetings',
      },
      {
        tabName: 'ir-contacts',
        tabValue: 5,
        tabLink: '/investor/ir-contacts',
      },
    ],
  },
  SUSTAINABILITY: {
    titles: [
      'OUR APPROACH',
      // 'SOCIAL RESPONSIBILITY', // Out commented for now
      'UN SDGs',
      'ESG REPORTING',
      'GRIEVANCE MECHANISM',
    ],
    paths: [
      '/sustainability/our-approach',
      // '/sustainability/social-responsibility', // Out commented for now
      '/sustainability/development-goals',
      '/sustainability/esg-resources',
      '/sustainability/grievance-mechanism',
    ],
    subPages: [
      'our-approach',
      // 'social-responsibility', // Out commented for now
      'sustainability',
      'esg-resources',
      'grievance-mechanism',
    ],
    sustainabilityTabs: [
      {
        tabName: 'our-apporach',
        tabValue: 0,
        tabLink: '/sustainability/our-apporach',
      },
      // Out commented for now
      // {
      //   tabName: 'social-responsibility',
      //   tabValue: 1,
      //   tabLink: '/sustainability/social-responsibility',
      // },
      {
        tabName: 'development-goals',
        tabValue: 1,
        tabLink: '/sustainability/development-goals',
      },
      {
        tabName: 'esg-resources',
        tabValue: 2,
        tabLink: '/sustainability/esg-resources',
      },
      {
        tabName: 'grievance-mechanism',
        tabValue: 3,
        tabLink: '/sustainability/grievance-mechanism',
      },
    ],
  },
};

// Colors
export const COLOR_CORAL = '#F34C4C';
export const COLOR_RED = '#FF6753';
export const COLOR_RED_VISITED = '#CB1010';
export const WHITE = '#F8F8F8';
export const BLACK = '#1D1D1D';
